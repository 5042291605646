body {
  font-family:L1DXRG;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: L1DXRG;
  src: url(L1DXRG.TTF);
}
.header {
  width: 100%;
  background-color: #DDE9F7;
  position: fixed; /* Make the header fixed */
  top: 0; /* Stick to the top */
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure the header is above other elements */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px; /* Set a max-width for better alignment */
  margin: auto; /* Center align the container */
  padding: 10px 20px;
}

.header .logo img {
  width: 80px;
}

.navbar {
  display: flex;
  gap: 30px;
  margin-right: 50px;
}

.navbar a {
  text-decoration: none;
  color: #3275c5;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.navbar a:hover {
 
  color: #56aaf0; 
  box-shadow: 0px 2px 3px #56aaf0;
}

.navbar .active {
  border: 2px solid #3275c5; /* Add border for active tab */
  background-color: #3275c5; /* Active tab background */
  color: #fff; /* Active tab text color */
}

.icons {
  display: none;
}

.icons .fa-bars {
  cursor: pointer;
  font-size: 24px;
  color: #333;
}


/* Scroll to Top Button */
.scroll-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #3275c5;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1000;
}

.scroll-top:hover {
  background-color: #56aaf0; /* Change color on hover */
}

.scroll-top i {
  font-size: 1.2rem; /* adjust size as needed */
}

/* Responsive Menu */
@media (max-width: 768px) {
  .navbar {
    display: none;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .navbar.active {
    display: flex;
  }

  .icons {
    display: block;
  }
}
