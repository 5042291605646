

  /* Footer content styles */
  .footer-container {
    position: relative;
    background-color: #ffffff;
    padding: 50px;
    box-shadow: 3px 0px 5px #3275c5;
    bottom: 0;
    box-sizing: border-box;
    
  }
  
  .footer-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    
  }
  
  .social-icons a {
    color: #3275c5;
    transition: color 0.3s ease;
    font-size: 10px;
   
  }
  
  .social-icons a:hover {
    color: #64caf4;
  }
  
  /* Centered text */
  .footer-content p {
    font-size: 10px;
    font-weight: bold;
    color: #3275c5;
  }
  .footer-content p a{
    text-decoration: none;
    color: #3275c5;
    font-style: normal;
  }
  .footer-content p a:hover{
    color: #64caf4;
  } 
