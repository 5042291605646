.Battery-Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* This will wrap elements on smaller screens */
    padding: 0 20px;
    text-align: left;
}

.video-container {
    flex: 1; /* Ensures that the video takes up equal space */
    padding-right: 10px; /* Adds spacing between video and image */
}

.image-container {
    flex: 1; /* Ensures that the image takes up equal space */
    padding-left: 10px;
}

.Battery {
    max-width: 100%; /* Makes the image responsive */
    height: auto; /* Maintains the aspect ratio */
  
}

.video-side {
    width: 100%; /* Ensures the video is responsive */
    height: auto;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .Battery-Container {
        flex-direction: column; /* Stacks the elements vertically on smaller screens */
    }
    
    .video-container, .image-container {
        padding: 0; /* Remove padding for smaller screens */
    }
}
