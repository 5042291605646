
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevents any horizontal overflow */
}
span
{
    background:linear-gradient(to left,#64caf4,#d4dbdb);
    color:#3275c5;
    padding: 10px;
    font-weight: bold;
}
h2{
    font-weight: bold;
}
.contact-container {
    background-color: #f8f9fa;
    padding: 20px;
  }
  
  .form-card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; /* Add space below form card */
  }
  
  .contact-info {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .icon-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #007bff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin: 0 auto;
  }
  .mail
  {
    color: #fff;
  }
  .contact-card h2 {
    color: #007bff;
  }
  
  .contact-card p {
    color: #666;
  }
  
  .contact-phone {
    font-size: 1.5rem;
    color: #007bff;
    font-weight: bold;
  }

  .contact-card {
    font-size: 1rem;
    transform-style: preserve-3d;
    color: #6c757d;
    font-weight: bold;   
  }

  @media (max-width: 767px) {
    .form-card {
      margin-bottom: 40px; /* Add more space on smaller screens */
    }
    
    .contact-info {
      padding: 10px; /* Reduce padding on smaller screens */
    }
  }