/* Service.css */

/* General Styling */
span {
  background: linear-gradient(to left, #64caf4, #d4dbdb);
  color: #3275c5;
  padding: 10px;
  font-weight: bold;
  
}

h2 {
  font-weight: bold;
  margin-bottom: 30px;
}

/* Icon Circle */
.icon-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #3275c5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: transform 0.3s ease;
  cursor: pointer;
  font-size: 24px;
}
h5{
  padding: 25px;
  color: #3275c5;
  text-align: center;
}

.icon-circle i {
  color: white;
  font-size: 2rem;
}

.service-card-container {
  perspective: 1000px;
  transition: transform 0.3s ease-in-out;
}

.service-card {
  position: relative;
  width: 100%;
  height: 250px; /* Adjusted for a better view */
  background: #fff;
  border-radius: 10px;  
  transform-style: preserve-3d;
  transition: transform 0.3s ease-in-out;
}

.service-card-container.flipped .service-card {
  transform: rotateY(180deg);
}

.service-front, .service-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.service-front {
  background-color: #ffffff;
  box-shadow: 1px 1px 1px 0px #6c757d;
  padding: 10px; /* Added padding */
}

.service-front:hover {
  box-shadow: 0px 3px 3px 0px #3275c5;
  
}

.service-back {
  background-color: #fff;
  transform: rotateY(180deg);
  padding: 10px; /* Padding for better spacing */
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-back p {
  color: #6c757d;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  border-top: 1px solid #3275c5;
  border-bottom: 1px solid #3275c5;

  padding: 30px;
  margin: 0; /* Remove margin for consistent spacing */
}

/* Hover Effect */
.service-card:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .icon-circle {
    width: 80px;
    height: 80px;
  }

  .service-card {
    height: 220px; /* Adjusted height for smaller screens */
  }

  .service-back p {
    font-size: 0.9rem;
  }
}
