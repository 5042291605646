.heading
{
    text-align: center;
    font-weight: bold;
}
.heading span
{
  background:linear-gradient(to left,#64caf4,#d4dbdb);
  color:#3275c5;
  padding: 10px;
  font-weight: bold;
}
.Container{
    border: 1px solid black;
}
.mission-vision-icon 
{
    color: #4f6a92;
    transition: transform 0.3s ease;
  }
  
.mission-vision-icon:hover {
    transform: scale(1.1);
  }
  
.mission-vision-card {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
   
    padding: 50px;
  }
  .mission-vision-card:hover{
    border:1px double #4f6a92;
    background-color: #d4dbdb;
  }
  
.card-title {
    font-size: 1.5rem;
  }
  
  .card-text {
    font-size: 1rem;
    transform-style: preserve-3d;
    color: #6c757d;
    font-weight: bold;
    border-top: 1px solid #3275c5;
    border-bottom: 1px solid #3275c5;
  }
  